















































































































































































import ViewModel from '@/views/contact/Question.ts';
export default ViewModel;
